<template>
    <div class="MyFollow">
      <!-- <p class="pageTop"></p> -->
      <div class="PageCommon">
        <div class="bscroll" ref="bscroll">
          <div class="bscroll-container">
        <h3 class="pageTitle" v-if="!mobileFlag">
        {{ $t("historicalProfit") }}
        
      </h3>
      <!-- <div class="refreshListBtnDiv">
        <el-button class="confirmDialogBtn" @click="getHistoryData" icon="el-icon-refresh-right">
          
          {{
            $t("ManualReplenishment_refresh")
          }}</el-button>
      </div> -->
            <div class="mobileFilterDiv" v-if="mobileFlag">
              <el-button class="mobileFilterBtn"  @click="openSearch()"  type="text" size="small">
                {{ $t("mobileFilterBtnTxt") }}
                <svg-icon class="greenIcon" icon-class="MobileFilterBtn"></svg-icon>
              </el-button>
            </div>
            
            <div class="tableSearch" v-if="!mobileFlag">

                <el-form label-position="top" :model="tableSearchForm" ref="tableSearchForm">
                  <el-form-item :label="$t('startSelectTime')" prop="settlementBeginTime">
                        <el-date-picker v-model="tableSearchForm.settlementBeginTime" 
                            value-format="yyyy-MM-dd" >
                        </el-date-picker>
                        <!-- <svg-icon class="dateIcon" icon-class="TimeIcon"></svg-icon> -->
                    </el-form-item>
                    <el-form-item :label="$t('settleSelectTime')" prop="settlementEndTime">
                        <el-date-picker v-model="tableSearchForm.settlementEndTime" 
                            value-format="yyyy-MM-dd" >
                        </el-date-picker>
                        <!-- <svg-icon class="dateIcon" icon-class="TimeIcon"></svg-icon> -->
                    </el-form-item>
                    <el-form-item :label="$t('MyFollowers_CustomerMt4Account')" prop="followAccount">
                        <el-input v-model="tableSearchForm.followAccount"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('echarts_symbol')" prop="portfolioName">
                        <el-input v-model="tableSearchForm.portfolioName"></el-input>
                    </el-form-item>
                    <!-- <el-form-item :label="$t('MyFollowers_Status')" prop="type">
                      <el-select v-model="tableSearchForm.type">
                        <el-option :label="$t('MyFollowers_AllStatus')" value=""></el-option>
                        <el-option :label="$t('MyFollowers_StopCopying1')" value="1"></el-option>
                        <el-option :label="$t('MyFollowers_RejectInvitation')" value="2"></el-option>
                         <el-option :label="$t('follow_startfollow')" value="3"></el-option> -->
                        <!-- <el-option :label="$t('MyFollowers_StopCopying1')" value="3"></el-option> -->
                        <!-- <el-option label="停止跟单" value="3"></el-option> 
                      </el-select>
                    </el-form-item> -->
                    <el-form-item class="noLabel">
                        <el-button class="serchFormBtn" @click="getHistoryData(1, pageSize)">
                            {{ $t('MyFollowers_Search') }}
                            <svg-icon icon-class="SearchIcon"></svg-icon>
                        </el-button>

                    </el-form-item>
                    <el-form-item class="noLabel">

                        <el-button class="resetFormBtn" @click="reset()">
                            {{ $t('MyFollowers_Reset') }}
                            <svg-icon icon-class="ResetIcon"></svg-icon>

                        </el-button>
                    </el-form-item>

                </el-form>
            </div>
            <div class="tableDiv" v-if="!mobileFlag">
              <el-table :data="historyTableData" border style="width: 100%" >
                <el-table-column width="80" prop="sortNum" v-if="!mobileFlag" :label="$t('MyFollowers_Number')">
                </el-table-column>
                <el-table-column width="150" prop="settlementBeginTime"
                  :label="$t('startSelectTime')">
                </el-table-column>
                <el-table-column width="150" prop="settlementEndTime"
                  :label="$t('settleSelectTime')">
                </el-table-column>
                
                <el-table-column :width="mobileFlag ? '120' : 'auto'" prop="signalName"
                  :label="$t('MyFollowers_SignalSourceName')">
                </el-table-column>
                <el-table-column :width="mobileFlag ? '120' : 'auto'" prop="signalAccount"
                  :label="$t('signal_num')">
                </el-table-column>
                <el-table-column :width="mobileFlag ? '120' : 'auto'" prop="followAccount" :label="$t('MyFollowers_MT4Account')">
                  
                </el-table-column>
                
                <el-table-column :width="mobileFlag ? '120' : 'auto'" prop="beginPeriodEquity"
                  :label="$t('InitialNetValue')">
                </el-table-column>
                <el-table-column :width="mobileFlag ? '120' : 'auto'" prop="currentPeriodDeposit"
                  :label="$t('CurrentDeposit')">
                </el-table-column>
                <el-table-column :width="mobileFlag ? '120' : 'auto'" prop="settlementPeriodEquity"
                  :label="$t('NetSettlementValue')">
                </el-table-column>
                <el-table-column :width="mobileFlag ? '120' : 'auto'" 
                  :label="$t('paymentProfitSharingRatio')">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.shareProfitPercent ? scope.row.shareProfitPercent+'%' : 0}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :width="mobileFlag ? '120' : 'auto'" prop="profit"
                  :label="$t('PaymentOfDividends')">
                </el-table-column>
                <el-table-column  prop="currentPeriodVolumes"
                :label="$t('CurrentTradingVolume')">
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.currentPeriodVolumes ? scope.row.currentPeriodVolumes  : 0
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column  prop="profit"
                :label="$t('MyFollowers_CopyDirection')">
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.followDirection
                        ? $t("MyFollowers_ReverseCopy")
                        : $t("MyFollowers_Copy")
                    }}
                  </div>
                </template>
              </el-table-column>
              </el-table>
              <div class="tableTotalNum">
              {{ $t('tableTotalvolumns') + ' : ' + totalVolume + ' ' + $t('tableTotalprofit') + ' : ' + totalProfit + ' '}}
            </div>
              <el-pagination class="tablePage" :prev-text="$t('MyFollowers_PreviousPage')"
                :next-text="$t('MyFollowers_NexPage')" @current-change="handleHistoryCurrentChange"
                :page-sizes="[5, 10, 20, 50, 100]" @size-change="handleHistorySizeChange" :current-page="historyCurrentPage"
                :page-size="historyPageSize" layout="prev, pager, next, sizes" :pager-count="5" :total="historyTotal">
              </el-pagination>
            </div>
        <!-- <h3 class="pageTitle">
                  {{ $t('MyFollowers_Myfollowers') }}
              </h3> -->
              <div class="mobileTableList" v-show="mobileFlag">
                <div class="tableTotalTop">
                  <span>{{ $t('tableTotalvolumns') }}</span>
                  <b>
                    {{ totalVolume }}
                  </b>
                </div>
                <div class="tableTotalTop">
                  <span>{{ $t('tableTotalprofit') }}</span>
                  <b :class="[totalProfit > 0 ?'greenNum':'redNum']">
                    {{ totalProfit }}
                  </b> 
                </div>
                <el-collapse accordion @change="collapseChange" v-model="activeCollapse">
                    <el-collapse-item v-for="(item,index) in historyTableData" :key="index">
                    <template slot="title">
                      <div class="mobileTableCard" @click.stop="showCollapse()">
                       
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('startSelectTime') }}
                          </p>
                          <p class="mobileTableContent" >
                              {{ item.settlementBeginTime }}
                          </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('settleSelectTime') }}
                          </p>
                          <p class="mobileTableContent" >
                              {{  item.settlementEndTime  }} 
                          </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('MyFollowers_SignalSourceName') }}
                          </p>
                          <p class="mobileTableContent" >
                              {{  item.signalName }} 
                          </p>
                          </div>
                          <div class="mobileTableItem">
                          <p class="mobileTableTitle" >
                              {{ $t('MyFollowers_SignalSourceName') }}
                          </p>
                          <p class="mobileTableContent" >
                            {{ item.portfolioName ? item.portfolioName : 0 }}
                          </p>
                          </div>
                          <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('signal_num') }}
                            </p>
                            <p class="mobileTableContent" >
                              {{ item.signalAccount }}
                            </p>
                          </div>
                          <div class="mobileTableItem">
                            <p class="mobileTableTitle" >
                                {{ $t('MyFollowers_MT4Account') }}
                            </p>
                            <p class="mobileTableContent" >
                              {{ item.followAccount }}
                            </p>
                          </div>
                      </div>
                      <div class="mobileTableBtns"  @click.stop="showCollapse()">
                        
                      </div>
                    </template>
                    <div class="mobileTableCard" >
                      <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('InitialNetValue') }}
                      </p>
                      <p class="mobileTableContent" >
                        <div>
                          {{ item.beginPeriodEquity }}
                        </div>
                      </p>
                      </div>
                      <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('CurrentDeposit') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.currentPeriodDeposit  }} 
                      </p>
                      </div>
                      <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('NetSettlementValue') }}
                      </p>
                      <p class="mobileTableContent" >
                        {{  item.settlementPeriodEquity }} 
                      </p>
                      </div>
                      <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('paymentProfitSharingRatio') }}
                      </p>
                      <p  class="mobileTableContent">
                          {{  item.shareProfitPercent ? item.shareProfitPercent+'%' : 0}}
                      </p>
                      </div>
                      <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('PaymentOfDividends') }}
                      </p>
                      <p  class="mobileTableContent">
                          {{  item.profit  }} 
                      </p>
                      </div>
                      <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('CurrentTradingVolume') }}
                      </p>
                      <p  class="mobileTableContent">
                        {{
                          item.currentPeriodVolumes ? item.currentPeriodVolumes  : 0
                        }}
                      </p>
                      </div>
                      <div class="mobileTableItem">
                      <p class="mobileTableTitle" >
                          {{ $t('MyFollowers_CopyDirection') }}
                      </p>
                      <p  class="mobileTableContent">
                        {{
                      item.followDirection
                        ? $t("MyFollowers_ReverseCopy")
                        : $t("MyFollowers_Copy")
                    }}
                      </p>
                      </div>
                  </div>
                </el-collapse-item>
                
                </el-collapse>
                <div class="x" v-if="up && historyTableData.length < historyTotal">
                      <i  class="el-icon-loading "></i>
                      {{ $t('loading') }}
                    </div>
                    <div class="x" v-if="!up && historyTableData.length >= historyTotal">
                      
                      {{ $t('noMoreData') }}
                    </div>
            </div>
      </div>

      </div>
      </div>
      <el-drawer
              class="mobileSearchDrawer"
              :visible.sync="mobileSearchDrawer"
              direction="ttb">
              <div class="mobileFilterReturnBtnDiv">
                <el-button  class="mobileFilterReturnBtn" @click="mobileSearchDrawer = false">
                    {{ $t('MyFollowers_Search') }}
                    <i class="el-icon-arrow-up"></i>
                </el-button>
              </div>
              
              <el-form label-position="top" :model="tableSearchForm" ref="tableSearchForm">
                <el-form-item :label="$t('startSelectTime')" prop="settlementBeginTime">
                        <el-date-picker v-model="tableSearchForm.settlementBeginTime" 
                            value-format="yyyy-MM-dd" >
                        </el-date-picker>
                        <!-- <svg-icon class="dateIcon" icon-class="TimeIcon"></svg-icon> -->
                    </el-form-item>
                    <el-form-item :label="$t('settleSelectTime')" prop="settlementEndTime">
                        <el-date-picker v-model="tableSearchForm.settlementEndTime" 
                            value-format="yyyy-MM-dd" >
                        </el-date-picker>
                        <!-- <svg-icon class="dateIcon" icon-class="TimeIcon"></svg-icon> -->
                    </el-form-item>
                    <el-form-item :label="$t('MyFollowers_CustomerMt4Account')" prop="followAccount">
                        <el-input v-model="tableSearchForm.followAccount"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('echarts_symbol')" prop="portfolioName">
                        <el-input v-model="tableSearchForm.portfolioName"></el-input>
                    </el-form-item>
                </el-form>
                <div class="mobileFilterBottomBtns">
                  <el-button class="resetFormBtn" @click="reset()">
                      {{ $t('MyFollowers_Reset') }}
                  </el-button>
                  <el-button class="serchFormBtn" @click="getHistoryData(1, pageSize)">
                      {{ $t('MyFollowers_Search') }}
                  </el-button>
                </div>
            </el-drawer>
    
      <div class="mobileLabelTips" ref="mobileLabelTips" v-if="isLableTips">
        <svg-icon class="labelIcon" icon-class="LabelIcon"></svg-icon>
        <p>
          {{ labelTitle }}
        </p>
        <div>
          {{ labelTxt }}
        </div>
      </div>
      <!-- <load2 /> -->
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { concatParams, concatTableData, dateGmt8 } from "../../plugins/common.js";
  import { mapState } from "vuex";
  import PopupTips from '@/components/moudule/PopupTips.vue'
  import load2 from '@/components/moudule/Loading.vue'
  import BScroll from '@better-scroll/core'
  import Pullup from '@better-scroll/pull-up'
  import { formatNumberFractionDigits } from "@/shared/utils/formatNumber.js";
  
  BScroll.use(Pullup)
  
  
  export default {
    name: "FollowHistory",
    components: {
      PopupTips,
      load2
    },
    computed: {
      ...mapState(["checkPortfolio", "userInfo", "token", 'mobileFlag']),
    },
    data() {
      return {
        tableData: [],
        currentPage: 1,
        total: 0,
        pageSize: 5,
        historyCurrentPage: 1,
        historyTotal: 0,
        historyPageSize: 5,
        checkInviteDrawer: false,
        fullscreenLoading: true,
        followRule: {
          account: [
            {
              required: true,
              message: this.$t("MyFollowers_checkMt4Account"),
              trigger: "blur",
            },
          ],
          cwPassword: [
            { required: true, message: this.$t('MyFollowers_Mt4PasswordNotNull'), trigger: 'blur' },
            // { type: 'number', message: this.$t('MyFollowers_TradeRatioMustNum') },
          ],
        },
        checkRow: {},
        isRefuse: false,
        isFollowing: false,
        followDrawerTitle: "",
        activeName: "follow",
        historyTableData: [],
        labelTitle: '',
        labelTxt: '',
        isLableTips: false,
        accountList: [],
        isPopupVisible: false,
        iconImg:require('../../assets/images/NotifyWarning.svg'),
        tableSearchForm: {
              followAccount: '',
                portfolioName : '',
                settlementEndTime:'',
                settlementBeginTime:''
        },
        totalProfit:0,
        totalVolume:0,
        mobileSearchDrawer:true,
	          up:false,
            activeCollapse:0,
      };
    },
    
    created() {
      this.getHistoryData(1,5);
  
    },
    methods: {
      formatNumberFractionDigits,
      collapseChange(){
      this.$nextTick(() => {
            
            if(!this.firstOpen){
              this.scroll.maxScrollY = this.scroll.maxScrollY - 150
              this.firstOpen = true
            }else{
              this.scroll.maxScrollY = this.scroll.maxScrollY + 150
              this.firstOpen = false
            }
            
            
          
      });
    
      // this.tableData = []
      // this.scrollFn()
    },
      openSearch(){
        this.mobileSearchDrawer = true
      },
      scrollFn(){
        this.$nextTick(() => {
         
            if (!this.scroll) {
                this.scroll = new BScroll(this.$refs.bscroll, {
                    click: true,
                    scrollY: true,
                    probeType: 3
                });
            }else{
                this.scroll.refresh();
            }
            
            //touchEnd（手指离开以后触发） 通过这个方法来监听下拉刷新
            this.scroll.on('touchEnd', (pos) => {
                //上拉加载 总高度>下拉的高度+10 触发加载更多
                if(this.scroll.maxScrollY>pos.y + 50){
                    // console.log("加载更多")
                    this.up=true;
                    setTimeout(()=>{
                    //使用refresh 方法 来更新scroll  解决无法滚动的问题
                        if(this.historyTableData.length < this.historyTotal){
                          this.currentPage = this.currentPage + 1
                          this.getHistoryData(this.currentPage,this.pageSize,'noRefresh')
                          this.scroll.refresh();
                          this.up=false;
                        }else{
                          this.up=false
                        }
                        
                   },1000) 
                   
                }
                // console.log(this.scroll.maxScrollY+"总距离----下拉的距离"+pos.y)
            })
          
            // console.log(this.scroll.maxScrollY)
        });
    },
      getHistoryData(pageIndex,pageSize,tag) {
        this.mobileSearchDrawer = false
        if(!this.mobileFlag || tag !== 'noRefresh'){
          this.tableData = [];

        }
        var params = {
          portfolioName: this.tableSearchForm.portfolioName.trim(),
          followAccount: this.tableSearchForm.followAccount?this.tableSearchForm.followAccount.trim():undefined,
          settleBeginTime: this.tableSearchForm.settlementBeginTime,
          settleEndTime: this.tableSearchForm.settlementEndTime,
          type:2,
          portfolioId: 0,
          pageIndex: pageIndex,
          pageSize: pageSize,
        };
        this.$axios
          .post("/Api/Invite/History" ,params)
          .then((res) => {
            let dt = res.data;
  
            if (dt.isSuccess) {
              // dt.result.pageList.forEach((item,index) =>{
              //     item.beginTime = dateGmt8(item.beginTime)
              //     item.endTime = dateGmt8(item.endTime)

              // })
              this.historyTotal = dt.result.total;
              this.totalProfit = dt.result.totalProfit;
              this.totalVolume = dt.result.totalVolume;
              if(this.mobileFlag && tag == 'noRefresh'){
                dt.result.pageList.forEach((item) =>{
                  this.historyTableData.push(item)
                })
                
                
              }else{
                this.historyTableData = concatTableData(
                    dt.result.pageList,
                    dt.result.total,
                    this.historyCurrentPage,
                    this.historyPageSize
                  );
              }
              if(this.mobileFlag){
                this.scrollFn()
              }
            }
          }).finally(()=>{
            // this.$refs.tableSearchForm.resetFields();

          })
      },
      handleHistoryCurrentChange(val) {
        this.historyCurrentPage = val;
        this.getHistoryData(this.historyCurrentPage, this.historyPageSize);
      },
      handleHistorySizeChange(val) {
        this.historyPageSize = val;
        this.getHistoryData(this.historyCurrentPage, this.historyPageSize);
      },
      toEcharts(account){
        this.$router.push({ path: '/FollowEcharts',query:{account:account} });
      },
      reset() {
        this.$refs.tableSearchForm.resetFields();
        this.mobileSearchDrawer = false
      },
      getSummaries(param) {
          const { columns, data } = param;
          const sums = [];
          columns.forEach((column, index) => {
              if (index === 0) {
                  sums[index] = this.$t('echarts_total');
                  return;
              }
              const values = data.map(item => Number(item[column.property]));
              if (column.property === 'profit' || column.property === 'volume' || column.property === 'equity' || column.property === 'balance') {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    
                    sums[index] = formatNumberFractionDigits(sums[index]) 
                } else {
                    sums[index] = ' '
                }
          });

          return sums;

      },
    },
  };
  </script>
  <style lang="less" scoped>
  .MyFollow {}
  .refreshListBtnDiv{
  position: absolute;
  right: 60px;
  top: 40px;
}
@media screen and (max-width:767px){
  .refreshListBtnDiv{
      position: absolute;
      right: 20px;
      top: 5px;
      .confirmDialogBtn{
        padding: 10px ;
        font-size: 12px;
        line-height: 15px;
      }
  }
}
  </style>
  